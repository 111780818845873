/***** Dataiku Brand Color *****/
/***** Universe color *****/
/***** Primary action colors *****/
/***** Greyscale *****/
/***** Status colors *****/
/******* Chart color *******/
/******* Colors per object types *******/
/******* Custom colors for plugin recipes *******/
/******* Variables helpers *******/
/****** Components *******/
/****** Buttons *******/
/****** Class helpers *******/
.text-important {
  color: #222222;
}
.text-weak {
  color: #666666;
}
.text-prompt {
  color: #BBBBBB;
}
.text-active {
  color: #31708F;
}
.text-disabled {
  color: #CCCCCC;
}
.text-error {
  color: #CE1228;
}
.text-success {
  color: #4CAF50;
}
.text-warning {
  color: #F28C37;
}
.success-background {
  background-color: #4CAF50;
}
.success-background--light {
  background-color: #E8F5E9;
}
.info-background {
  background-color: #31708F;
}
.info-background--light {
  background-color: #E6EEF2;
}
.error-background {
  background-color: #CE1228;
}
.error-background--light {
  background-color: #F9E3E5;
}
.warning-background {
  background-color: #F28C37;
}
.warning-background--light {
  background-color: #FDF1E7;
}
.link-normal {
  color: #2D86FB;
}
.link-hover {
  color: #267CFB;
}
.link-visited {
  color: #196BFA;
}
.object-link {
  color: #222222;
}
.object-link-hover {
  color: #222222;
}
.border {
  border-color: #CCCCCC;
}
.line-hover {
  background-color: #F2F2F2;
}
.line-selected {
  background-color: #E7F3FF;
}
.odd-line {
  background-color: #F2F2F2;
}
.divider {
  border-bottom: #DDDDDD;
}
.disabled {
  color: #CCCCCC;
}
.page-background {
  background-color: #FFFFFF;
}
.background {
  background-color: #F2F2F2;
}
.chips {
  color: #F2F2F2;
}
.universe-color.home {
  color: #2AB1AC;
}
.universe-color.flow,
.universe-color.recipe {
  color: #F9BD38;
}
.universe-color.datasets,
.universe-color.dataset {
  color: #28A9DD;
}
.universe-color.managed-folder,
.universe-color.managed_folder {
  color: #28A9DD;
}
.universe-color.analysis {
  color: #29AF5D;
}
.universe-color.notebooks,
.universe-color.notebook,
.universe-color.web_app,
.universe-color.report {
  color: #F28C38;
}
.universe-color.job,
.universe-color.jobs,
.universe-color.scenario {
  color: #8541AA;
}
.universe-color.dashboard {
  color: #F44336;
}
.universe-color.project {
  color: #333333;
}
.universe-color.column {
  color: #333333;
}
.universe-color.discussion {
  color: #333333;
}
.universe-color.wiki,
.universe-color.article {
  color: #427B98;
}
.universe-color.bundle {
  color: #54707E;
}
.universe-color.metrics {
  color: #333333;
}
.universe-color.recipe {
  color: #F28C38;
}
.universe-color.recipe-visual {
  color: #F9BD38;
}
.universe-color.recipe-custom {
  color: #AA4141;
}
.universe-color.recipe-custom-red {
  color: #E13D3C;
}
.universe-color.recipe-custom-pink {
  color: #E91E63;
}
.universe-color.recipe-custom-purple {
  color: #8C2DA7;
}
.universe-color.recipe-custom-blue {
  color: #31439C;
}
.universe-color.recipe-custom-green {
  color: #0F786B;
}
.universe-color.recipe-custom-sky {
  color: #00BCD4;
}
.universe-color.recipe-custom-yellow {
  color: #CDDC39;
}
.universe-color.recipe-custom-orange {
  color: #E44B27;
}
.universe-color.recipe-custom-brown {
  color: #795548;
}
.universe-color.recipe-custom-gray {
  color: #465A64;
}
.universe-color.recipe-code {
  color: #F28C38;
}
.universe-color.recipe-train,
.universe-color.recipe-score,
.universe-color.recipe-clustering,
.universe-color.recipe-evaluation {
  color: #29AF5D;
}
.universe-color.insight {
  color: #F44336;
}
.universe-color.saved-model,
.universe-color.saved_model {
  color: #29AF5D;
}
.universe-color.chart {
  color: #3075AE;
}
.universe-color.meaning {
  color: #333333;
}
.universe-color.plugin {
  color: #444444;
}
.universe-color.more {
  color: #54707E;
}
.universe-color.navigation {
  color: #666666;
}
.universe-background {
  color: #FFFFFF !important;
  background: #2AB1AC;
}
.universe-background.home {
  background-color: #2AB1AC;
}
.universe-background.flow,
.universe-background.recipe {
  background-color: #F9BD38;
}
.universe-background.datasets,
.universe-background.dataset {
  background-color: #28A9DD;
}
.universe-background.chart {
  background-color: #3075AE;
}
.universe-background.managed-folder,
.universe-background.managed_folder {
  background-color: #28A9DD;
}
.universe-background.analysis {
  background-color: #29AF5D;
}
.universe-background.notebooks,
.universe-background.notebook,
.universe-background.web_app,
.universe-background.report {
  background-color: #F28C38;
}
.universe-background.job,
.universe-background.jobs,
.universe-background.scenario {
  background-color: #8541AA;
}
.universe-background.dashboard,
.universe-background.insight {
  background-color: #F44336;
}
.universe-background.discussion,
.universe-background.metrics {
  background-color: #666666;
}
.universe-background.wiki,
.universe-background.article {
  background-color: #427B98;
}
.universe-background.bundle {
  background-color: #54707E;
}
.universe-background.recipe-visual {
  background-color: #F9BD38;
}
.universe-background.recipe-code {
  background-color: #F28C38;
}
.universe-background.recipe-train,
.universe-background.recipe-score,
.universe-background.recipe-clustering,
.universe-background.recipe-evaluation,
.universe-background.saved-model,
.universe-background.saved_model {
  background-color: #29AF5D;
}
.universe-background.recipe-custom {
  background-color: #AA4141;
}
.universe-background.recipe-custom-red {
  background-color: #E13D3C;
}
.universe-background.recipe-custom-pink {
  background-color: #E91E63;
}
.universe-background.recipe-custom-purple {
  background-color: #8C2DA7;
}
.universe-background.recipe-custom-blue {
  background-color: #31439C;
}
.universe-background.recipe-custom-green {
  background-color: #0F786B;
}
.universe-background.recipe-custom-sky {
  background-color: #00BCD4;
}
.universe-background.recipe-custom-yellow {
  background-color: #CDDC39;
}
.universe-background.recipe-custom-orange {
  background-color: #E44B27;
}
.universe-background.recipe-custom-brown {
  background-color: #795548;
}
.universe-background.recipe-custom-gray {
  background-color: #465A64;
}
.universe-background.shared-objects {
  background-color: #F9BD38;
}
.universe-background.shared-objects.object-icon i.icon {
  color: #222222 !important;
}
.universe-background.plugin {
  background-color: #444444;
}
.universe-background.admin {
  background-color: #444444;
}
.universe-background.catalog {
  background-color: #444444;
}
.universe-background.inbox {
  background-color: #444444;
}
.universe-background.meanings {
  background-color: #444444;
}
.universe-background.api-deployer {
  background-color: #444444;
}
.universe-background.profile {
  background-color: #444444;
}
.universe-background.alation {
  background-color: #444444;
}
.universe-fill {
  color: #FFFFFF !important;
  fill: #666666;
}
.universe-fill.home {
  fill: #2AB1AC;
}
.universe-fill.flow,
.universe-fill.recipe {
  fill: #F9BD38;
}
.universe-fill.datasets,
.universe-fill.dataset {
  fill: #28A9DD;
}
.universe-fill.managed-folder,
.universe-fill.managed_folder {
  fill: #28A9DD;
}
.universe-fill.analysis {
  fill: #29AF5D;
}
.universe-fill.notebooks,
.universe-fill.notebook {
  fill: #F28C38;
}
.universe-fill.job,
.universe-fill.jobs,
.universe-fill.scenario {
  fill: #8541AA;
}
.universe-fill.dashboard {
  fill: #F44336;
}
.universe-fill.project {
  fill: #222222;
}
.universe-fill.column {
  fill: #28A9DD;
}
.universe-fill.discussions {
  color: #434A54;
}
.universe-fill.metrics {
  fill: #666666;
}
.universe-fill.wiki,
.universe-fill.article {
  fill: #427B98;
}
.universe-fill.recipe {
  fill: #F28C38;
}
.universe-fill.recipe-visual {
  fill: #F9BD38;
}
.universe-fill.recipe-custom {
  fill: #AA4141;
}
.universe-fill.recipe-custom-red {
  fill: #E13D3C;
}
.universe-fill.recipe-custom-pink {
  fill: #E91E63;
}
.universe-fill.recipe-custom-purple {
  fill: #8C2DA7;
}
.universe-fill.recipe-custom-blue {
  fill: #31439C;
}
.universe-fill.recipe-custom-green {
  fill: #0F786B;
}
.universe-fill.recipe-custom-sky {
  fill: #00BCD4;
}
.universe-fill.recipe-custom-yellow {
  fill: #CDDC39;
}
.universe-fill.recipe-custom-orange {
  fill: #E44B27;
}
.universe-fill.recipe-custom-brown {
  fill: #795548;
}
.universe-fill.recipe-custom-gray {
  fill: #465A64;
}
.universe-fill.recipe-code {
  fill: #F28C38;
}
.universe-fill.recipe-train,
.universe-fill.recipe-score,
.universe-fill.recipe-clustering,
.universe-fill.recipe-evaluation {
  fill: #29AF5D;
}
.universe-fill.insight {
  fill: #F44336;
}
.universe-fill.saved-model,
.universe-fill.saved_model {
  fill: #29AF5D;
}
.universe-fill.chart {
  fill: #3075AE;
}
.universe-fill.meaning {
  fill: #28A9DD;
}
.color-visual-recipe {
  color: #F9BD38;
}
.color-code-recipe {
  color: #F28C38;
}
.color-ml-recipe {
  color: #29AF5D;
}
.typo-extralarge {
  font-family: 'SourceSansPro';
  font-weight: 400;
  font-size: 26px;
  font-height: 36px;
}
.typo-large {
  font-family: 'SourceSansPro';
  font-weight: 400;
  font-size: 22px;
  font-height: 30px;
}
.typo-medium {
  font-family: 'SourceSansPro';
  font-weight: 600;
  font-size: 18px;
  font-height: 25px;
}
.typo-medium {
  font-family: 'SourceSansPro';
  font-weight: 600;
  font-size: 16px;
  font-height: 22px;
}
.mx-link-nodecoration:hover {
  text-decoration: none;
}
.mx-link-nodecoration:focus {
  outline: none;
}
.mx-textellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.mat-option.mat-active {
  background: rgba(34, 34, 34, 0.04);
  color: rgba(34, 34, 34, 0.97);
}
.mat-autocomplete-panel {
  background: #FFFFFF;
  color: rgba(34, 34, 34, 0.97);
  min-width: 112px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  visibility: hidden;
  max-width: none;
  max-height: 256px;
  position: relative;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.mx-capped-height {
  max-height: calc(100vh - 60px);
}
.dku-modal-panel-wide .mat-dialog-container,
.dku-modal-panel-narrow .mat-dialog-container,
.dku-modal-panel-none .mat-dialog-container {
  box-shadow: 4px 4px 10px rgba(34, 34, 34, 0.15);
  padding: 0;
  border-radius: 0;
}
.dku-modal-panel-wide .mat-dialog-container > * > *,
.dku-modal-panel-narrow .mat-dialog-container > * > *,
.dku-modal-panel-none .mat-dialog-container > * > * {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.dku-modal-panel-wide .mat-dialog-container modal-header,
.dku-modal-panel-narrow .mat-dialog-container modal-header,
.dku-modal-panel-none .mat-dialog-container modal-header {
  flex: 0 0 auto;
}
.dku-modal-panel-wide .mat-dialog-container .modal-body,
.dku-modal-panel-narrow .mat-dialog-container .modal-body,
.dku-modal-panel-none .mat-dialog-container .modal-body {
  position: relative;
  flex: 1 1 auto;
  max-height: none;
}
.dku-modal-panel-wide .mat-dialog-container {
  width: 900px;
  max-height: calc(100vh - 60px);
}
.dku-modal-panel-narrow .mat-dialog-container {
  width: 560px;
  max-height: calc(100vh - 60px);
}
.dku-modal-panel-wide,
.dku-modal-panel-narrow,
.dku-modal-panel-none {
  margin: auto;
}
.dku-modal-panel-wide .modal-header,
.dku-modal-panel-narrow .modal-header,
.dku-modal-panel-none .modal-header {
  border-bottom: none;
  padding: 0;
  height: 70px;
  position: relative;
  background-color: #FFFFFF;
}
.dku-modal-panel-wide .modal-header.has-border,
.dku-modal-panel-narrow .modal-header.has-border,
.dku-modal-panel-none .modal-header.has-border {
  border-bottom: 1px solid #CCCCCC;
}
.dku-modal-panel-wide .modal-header.no-totem .modal-title,
.dku-modal-panel-narrow .modal-header.no-totem .modal-title,
.dku-modal-panel-none .modal-header.no-totem .modal-title {
  padding-left: 20px;
}
.dku-modal-panel-wide .modal-header .modal-totem,
.dku-modal-panel-narrow .modal-header .modal-totem,
.dku-modal-panel-none .modal-header .modal-totem {
  position: absolute;
  left: 20px;
  top: 25px;
  width: 18px;
  height: 28px;
  text-align: center;
  vertical-align: middle;
}
.dku-modal-panel-wide .modal-header .modal-totem.modal-totem--action,
.dku-modal-panel-narrow .modal-header .modal-totem.modal-totem--action,
.dku-modal-panel-none .modal-header .modal-totem.modal-totem--action {
  cursor: pointer;
  padding: 0 6px 0 0;
}
.dku-modal-panel-wide .modal-header .modal-totem i,
.dku-modal-panel-narrow .modal-header .modal-totem i,
.dku-modal-panel-none .modal-header .modal-totem i {
  line-height: 28px;
  font-size: 18px;
  text-align: center;
  display: inline;
  opacity: 0.6;
}
.dku-modal-panel-wide .modal-header .modal-totem i:before,
.dku-modal-panel-narrow .modal-header .modal-totem i:before,
.dku-modal-panel-none .modal-header .modal-totem i:before {
  vertical-align: middle;
}
.dku-modal-panel-wide .modal-header .modal-title,
.dku-modal-panel-narrow .modal-header .modal-title,
.dku-modal-panel-none .modal-header .modal-title {
  margin: 0;
  padding: 25px 0 0 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  font-family: 'SourceSansPro';
  font-weight: lighter;
  font-size: 22px;
  line-height: initial;
  color: #333333;
}
.dku-modal-panel-wide .modal-header .modal-title.has-icon,
.dku-modal-panel-narrow .modal-header .modal-title.has-icon,
.dku-modal-panel-none .modal-header .modal-title.has-icon {
  padding-left: 55px;
}
.dku-modal-panel-wide .modal-header .modal-header-button,
.dku-modal-panel-narrow .modal-header .modal-header-button,
.dku-modal-panel-none .modal-header .modal-header-button {
  float: right;
  margin-top: 30px;
  margin-right: 20px;
  padding: 0;
  border: 0;
  cursor: pointer;
  outline: none;
  font-size: 20px;
  font-weight: bold;
  line-height: 10px;
  height: 15px;
  color: #BBBBBB;
  text-decoration: none;
}
.dku-modal-panel-wide .modal-header .modal-header-button:hover,
.dku-modal-panel-narrow .modal-header .modal-header-button:hover,
.dku-modal-panel-none .modal-header .modal-header-button:hover {
  color: #666666;
  text-decoration: none;
}
.dku-modal-panel-wide .modal-header-only,
.dku-modal-panel-narrow .modal-header-only,
.dku-modal-panel-none .modal-header-only {
  height: auto;
  min-height: 70px;
  margin-bottom: 20px;
}
.dku-modal-panel-wide .modal-header-only h4,
.dku-modal-panel-narrow .modal-header-only h4,
.dku-modal-panel-none .modal-header-only h4 {
  overflow: auto;
  text-overflow: inherit;
  white-space: inherit;
}
.dku-modal-panel-wide .modal-header-only h4 strong,
.dku-modal-panel-narrow .modal-header-only h4 strong,
.dku-modal-panel-none .modal-header-only h4 strong {
  font-weight: normal;
}
.dku-modal-panel-wide .modal-body,
.dku-modal-panel-narrow .modal-body,
.dku-modal-panel-none .modal-body {
  padding: 20px;
}
.dku-modal-panel-wide .modal-body.tight-body,
.dku-modal-panel-narrow .modal-body.tight-body,
.dku-modal-panel-none .modal-body.tight-body {
  padding-bottom: 0;
}
.dku-modal-panel-wide .modal-body.no-padding,
.dku-modal-panel-narrow .modal-body.no-padding,
.dku-modal-panel-none .modal-body.no-padding {
  padding: 0;
}
.dku-modal-panel-wide .modal-body.no-h-padding,
.dku-modal-panel-narrow .modal-body.no-h-padding,
.dku-modal-panel-none .modal-body.no-h-padding {
  padding-left: 0;
  padding-right: 0;
}
.dku-modal-panel-wide .modal-body.no-v-padding,
.dku-modal-panel-narrow .modal-body.no-v-padding,
.dku-modal-panel-none .modal-body.no-v-padding {
  padding-bottom: 0;
  padding-top: 0;
}
.dku-modal-panel-wide .modal-body .modal-h-padding,
.dku-modal-panel-narrow .modal-body .modal-h-padding,
.dku-modal-panel-none .modal-body .modal-h-padding {
  padding-left: 20px;
  padding-right: 20px;
}
.dku-modal-panel-wide .modal-body--tabs,
.dku-modal-panel-narrow .modal-body--tabs,
.dku-modal-panel-none .modal-body--tabs {
  padding: 0;
  overflow: hidden;
}
.dku-modal-panel-wide .modal-body--tabs .mat-tab-body-wrapper,
.dku-modal-panel-narrow .modal-body--tabs .mat-tab-body-wrapper,
.dku-modal-panel-none .modal-body--tabs .mat-tab-body-wrapper {
  padding: 0 24px;
  height: 450px;
}
.dku-modal-panel-wide .modal-body--tabs .mat-tab-body-content,
.dku-modal-panel-narrow .modal-body--tabs .mat-tab-body-content,
.dku-modal-panel-none .modal-body--tabs .mat-tab-body-content {
  overflow: hidden;
}
.dku-modal-panel-wide .fixed-height500,
.dku-modal-panel-narrow .fixed-height500,
.dku-modal-panel-none .fixed-height500 {
  height: 500px;
  max-height: 500px;
  box-sizing: border-box;
}
.dku-modal-panel-wide .fixed-height250,
.dku-modal-panel-narrow .fixed-height250,
.dku-modal-panel-none .fixed-height250 {
  height: 250px;
  max-height: 250px;
  box-sizing: border-box;
}
.dku-modal-panel-wide .modal-footer,
.dku-modal-panel-narrow .modal-footer,
.dku-modal-panel-none .modal-footer {
  background-color: #FFFFFF;
  height: 50px;
  padding: 7px 10px 8px 0;
  line-height: 34px;
  box-sizing: border-box;
  border-top: none;
}
.dku-modal-panel-wide .modal-footer.has-border,
.dku-modal-panel-narrow .modal-footer.has-border,
.dku-modal-panel-none .modal-footer.has-border {
  border-top: 1px solid #CCCCCC;
}
.modal-footer-std-buttons {
  font-size: 0;
}
.modal-footer-std-buttons .btn {
  font-size: 15px;
  padding: 2px 10px;
  margin-left: 10px;
}
.modal-footer-std-buttons .btn-default {
  color: #666666;
  background-color: #FFFFFF;
}
.modal-footer-std-buttons .btn-default:hover {
  color: #FFFFFF;
  background-color: #666666;
}
.modal-footer-std-buttons .btn-default:active {
  background: #333333;
  color: #FFFFFF;
}
.modal-footer-std-buttons .btn-primary {
  color: #3B99FC;
  background: #FFFFFF;
}
.modal-footer-std-buttons .btn-primary:focus {
  color: #3B99FC;
  background: #FFFFFF;
}
.modal-footer-std-buttons .btn-primary:hover {
  color: #FFFFFF;
  background: #58A8FC;
}
.modal-footer-std-buttons .btn-primary:active {
  background: #3591FC;
  color: #FFFFFF;
}
.modal-footer-std-buttons .btn-primary:disabled {
  background: #FFFFFF;
  color: #9DCCFE;
}
.modal-footer-std-buttons .btn-primary.light-style {
  border: 1px #3B99FC solid;
}
.modal-footer-std-buttons .btn-primary.light-style:focus {
  color: #3B99FC;
  background: #FFFFFF;
}
.modal-footer-std-buttons .btn-primary.light-style:hover {
  color: #FFFFFF;
  background: #58A8FC;
}
.modal-footer-std-buttons .btn-primary.light-style:active {
  background: #3591FC;
  color: #FFFFFF;
}
.modal-footer-std-buttons .btn-primary.light-style:disabled {
  background: #FFFFFF;
  color: #F0F8E8;
}
.modal-footer-std-buttons .btn-danger {
  background: #FFFFFF;
  color: #CE1228;
}
.modal-footer-std-buttons .btn-danger:hover {
  color: #FFFFFF;
  background: #D53648;
}
.modal-footer-std-buttons .btn-danger:active,
.modal-footer-std-buttons .btn-danger:focus {
  background: #C91024;
  color: #FFFFFF;
}
.modal-footer-std-buttons .btn-danger:disabled {
  background: #FFFFFF;
  color: #F9E3E5;
  opacity: 0.3;
}
.modal-footer-std-buttons .btn-teal {
  background: #FFFFFF;
  color: #2AB1AC;
}
.modal-footer-std-buttons .btn-teal:hover {
  color: #FFFFFF;
  background: rgba(42, 177, 172, 0.6);
}
.modal-footer-std-buttons .btn-teal:active,
.modal-footer-std-buttons .btn-teal:focus {
  color: #FFFFFF;
  background: rgba(42, 177, 172, 0.9);
}
.cdk-overlay-container.see-through ul.modal-tabs,
.cdk-overlay-container.see-through div.project-tiles {
  transition: visibility 0s;
  visibility: hidden;
}
.cdk-overlay-container.see-through .mat-dialog-container,
.cdk-overlay-container.see-through .modal-header-button,
.cdk-overlay-container.see-through .modal {
  transition: background-color 0.5s;
  transition-delay: 0.25s;
  background-color: transparent;
}
.cdk-overlay-container.see-through div.modal-body,
.cdk-overlay-container.see-through div.modal-footer {
  transition: visibility 0s;
  transition-delay: 0.25s;
  visibility: hidden;
}
.cdk-overlay-container.see-through div.modal-header {
  transition: opacity 0.5s;
  opacity: 0.5;
}
.cdk-overlay-container.see-through .see-through-button {
  color: #222222;
  opacity: 1;
}
div.popover.see-through {
  transition: visibility 0s;
  transition-delay: 0.25s;
  visibility: hidden;
}
/**
 * The IO modal is split in two halves with a "Input -> Output" header
 */
.io-modal {
  font-weight: normal;
  letter-spacing: 0px;
}
.io-modal .modal-body {
  padding: 0px;
}
.io-modal .modal-body .half-pane.half-pane {
  height: 100%;
  width: 50%;
  box-sizing: border-box;
  float: left;
}
.io-modal .modal-body .half-pane.half-pane:first-child {
  border-right: 1px solid #CCCCCC;
  padding-right: 0px;
}
.io-modal .modal-body .half-pane.half-pane:last-child {
  padding-left: 0px;
}
.io-modal .modal-body .half-pane.half-pane h4 {
  font-family: 'SourceSansPro';
  font-weight: lighter;
  font-size: 19px;
  margin: 0px;
}
.io-modal .modal-body .half-pane.half-pane h4 i:after {
  content: "   ";
}
.io-modal .modal-body .half-pane.half-pane .header-text {
  border-bottom: 1px solid #CCCCCC;
  line-height: 45px;
  color: #666666;
  text-align: center;
}
